import Container from "../components/UI/Container"
import { ContentWrapper } from "../containers/elegant.style"
import Fade from "react-reveal/Fade"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import React from "react"
import SEO from "../components/SEO"
import Text from "../components/Text"
import febOffers from "../common/assets/image/elegant/feb-offers.jpg"

const OffersPage = ({ location }) => {
  const url = location.href ? location.href : ""
  return (
    <>
      <SEO
        url={url}
        title="Treatment Offers"
        shortDesc="Elegant Beauty Clinic"
        description="Find a complete offer list of our services to help you choose your next aesthetic service, facial, blood test or travel vaccination. Book online today."
      />
      <Layout>
        <ContentWrapper>
          <PageTitle
            title="Elegant Clinic Offers"
            subtitle="Treatment Offers"
          />
          {/* <TreatmentsLinks />
          <Offers /> */}
          <Container width="898px" noGutter>
            <Fade up delay={100}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "40px",
                  marginBottom: "40px",
                }}
              >
                {/* <Text content="No offers available at the moment." /> */}
                {/* <h2>No offers avaiable</h2> */}
                <img
                  src={febOffers}
                  alt="offers"
                  style={{ maxWidth: "500px" }}
                />
              </div>
            </Fade>
          </Container>
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default OffersPage
